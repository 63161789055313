<!--
 * @Description: 营业汇总 饼状图
 * @Author: 琢磨先生
 * @Date: 2023-01-16 14:14:51
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-16 15:50:56
-->
<template>
  <div class="pie">
    <v-chart :option="pieOption" autoresize />
  </div>
</template>

<script>
export default {
  data() {
    return {
        pieOption:{},
    };
  },
  props: ["model"],
  watch: {
    model: {
      handler() {
        if (this.model) {
          this.pieOption = {
            title: {
              text: `￥${this.model.total.decimal_amount}`,
              subtext: "住宿总营业额",
              top: "35%",
              left: "center",
            },
            tooltip: {
              trigger: "item",
              formatter: "{b}<br />￥{c} ({d}%)",
            },
            legend: {
              bottom: "5%",
              left: "center",
            },
            series: [
              {
                name: "",
                type: "pie",
                radius: ["35%", "50%"],
                center: ["50%", "40%"],
                data: [
                  {
                    value: this.model.room_fee.decimal_amount,
                    name: "房费",
                  },
                  {
                    value: this.model.penal_sum.decimal_amount,
                    name: "违约金",
                  },
                  {
                    value: this.model.consume.decimal_amount,
                    name: "客房消费",
                  },
                ],
                label: {
                  formatter: "{b}￥{c}({d}%)",
                },
              },
            ],
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style  scoped>
</style>